.iconImg {
    height: 50px;
    width: 50px;
    border-radius: 10px;
}

.appShortInfos {
    max-width: 300px;
}

.appShortInfosContainer {
    margin-bottom: 10px;
}

.appShortInfosContainer > div {
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(229, 229, 234);
}

.dlButton {
    border-bottom-left-radius: 25% 50%;
    border-bottom-right-radius: 25% 50%;
    border-top-left-radius: 25% 50%;
    border-top-right-radius: 25% 50%;
    height: 25px;
}

.dlButton > a {
    position: relative;
    bottom: 3px;
    color: rgb(0, 127, 255);
    text-decoration: none;
}

.search input {
    width: 100%;
    height: 2.42rem;
    border: 0;
    background: #f0f1f6 url(https://ipa.samf.me/search.27a3c877.png) .54rem no-repeat;
    background-size: .95rem auto;
    font-size: 1.08rem;
    border-radius: 1.21rem;
    line-height: 2.42rem;
    padding-left: 30px;
    max-width: 435px;
    margin: 10px 10px 20px 10px;
}

.search input:focus {
    outline: 0;
}
